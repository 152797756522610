* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    line-height: 1.5;
    font-family: 'Roboto', sans-serif;
    color: #333;
    :focus {
        outline: none;
    }
}

a {
    text-decoration: none;
}