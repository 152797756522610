$square: 32px;
$duration: 10s;

.loader {
    @include flex(row, center, center);
    margin-top: 2rem;
}

@keyframes square-animation {
  0% {
    left: 0;
    top: 0;
  }

  10.5% {
    left: 0;
    top: 0;
  }
  12.5% {
    left: $square;
    top: 0;
  }

  23% {
    left: $square;
    top: 0;
  }
  25% {
    left: $square * 2;
    top: 0;
  }

  35.5% {
    left: $square * 2;
    top: 0;
  }
  
  37.5% {
    left: $square * 2;
    top: $square;
  }

  48% {
    left: $square * 2;
    top: $square;
  }
  50% {
    left: $square;
    top: $square;
  }

  60.5% {
    left: $square;
    top: $square;
  }
  62.5% {
    left: $square;
    top: $square * 2;
  }

  73% {
    left: $square;
    top: $square * 2;
  }
  75% {
    left: 0;
    top: $square * 2;
  }

  85.5% {
    left: 0;
    top: $square * 2;
  }
  87.5% {
    left: 0;
    top: $square;
  }

  98% {
    left: 0;
    top: $square;
  }
  100% {
    left: 0;
    top: 0;
  }
}

@keyframes hue-rotate {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}

.loading {
  position: relative;
  height: $square * 3;
  width: $square * 3;
  transform: rotate(45deg);
  animation: hue-rotate $duration linear infinite both;
}

.loading__square {
  position: absolute;
  height: $square - 4px;
  width: $square - 4px;
  margin: 2px;

  top: 0;
  left: 0;
  border-radius: 2px;
  background: #07a;
  
  background-image: linear-gradient(45deg, #fa0 40%, #0c9 60%);
  background-image: -moz-linear-gradient(#fa0, #fa0);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  animation: square-animation $duration ease-in-out infinite both;

  @for $i from 0 through 7 {
    &:nth-of-type(#{$i}) {
      animation-delay: -($duration / 7) * $i;
    }
  }
}